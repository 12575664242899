import { Page } from './Page'

const Barter: Page = {
    title: 'Barter React Native Trading Application',
    description: "React Native + Azure Function mobile application that lets users trade items with each other.",
    projectDates: '2019',
    keywords: [
        'react native', 
        'graphql', 
        'apollo', 
        'neo4j', 
        'cypher', 
        'azure functions', 
        'node', 
        'typescript', 
        'javascript',
        'graph database',
        'android',
        'ios',
        'mobile',
        'react hooks',
        'frontend',
        'backend',
        'full stack',
        'nosql',
        'projects'
    ],
    technologies: ['typescript', 'cypher', 'javascript', 'graphql', 'react native', 'node', 'neo4j', 'azure functions', 'ajax'],
    content: [
        {
            header: "Using React Native",
            content: `Although I was eager to try native development with Swift and Java on iOS and Android,
            I chose React Native because it seemed like the most efficient solution for cross platform mobile
            development. Having come from a React background with web applications, the transition was seamless.`
        },
        {
            header: "Neo4j and Cypher",
            content: `The data model for Barter had many related entities: users, the items they owned,
            the items they were interested in, questions and answers about items, reviews, etc. I chose Neo4j
            so that I could model this heavily interconnected data in a graph, and the cypher language proved
            to be well-suited for executing these queries.`
        },
        {
            header: "Azure Functions Backend",
            content: `I've used dedicated web servers and docker containers for backends in the past.
            But FaaS with Azure Functions seemed like the most cost effective way to provide backend
            business logic. I used HTTP triggers to make requests from the front end. The Azure Functions
            have access to a container running a Neo4j instance. Requests go from the mobile app to the 
            Azure Function app, into the Neo4j container, and then the Azure Function app sends responses
            back to the user. A full round trip usually takes mere milliseconds.`
        },
        {
            header: "GraphQL Backend API with Neo4j / Cypher",
            content: `Using GraphQL with a Neo4j graph database made the backend code almost non-existent. 
            It's amazing. First I created the schema, including only the types of data in my graph.
            Then, I used the neo4j-graphql library to automatically generate basic CRUD cypher 
            queries based on the schema. Occasionally, there were specific queries or mutations
            I wanted that weren't autogenerated. Creating those was just a matter of slight configuration
            changes and writing the appropriate cypher queries to resolve those requests. Other than that,
            everyhing comes from the schema, and there's only one endpoint. 
            Less code and boilerplate. More time developing features. 
            I definitely want to use GraphQL more in the future.`
        },
        {
            content: `I use Apollo on the frontend to interact with the API in React.
            I'm able to rely Apollo's many built-in features, such as subscribing to data changes and 
            asynchronous fetching. This lets development move more quickly, as I don't have to reinvent the wheel
            for these common problems.
            .`
        },
    ],
    url: 'barter-react-native-app',
    rank: 0
}

export default Barter